import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import marked from 'marked'
import BG from '../images/awards-bg.jpg'

class BlogPostTemplate extends React.Component {
	render() {
		const post = this.props.data.sanityBlog
		const siteTitle = this.props.data.site.siteMetadata.title
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={post.title} description={post.excerpt} />
				<div
					id="sliderhead"
					className="blog-header blogheadpost"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
				>
					<div className="container text-left">
						<div className="text-det">
							<h2
								style={{ opacity: '1', transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
							>
								{post.title}
							</h2>
							<div className="date-head">
								<h6>
									<i className="fa fa-bookmark-o" /> <i>By</i>{' '}
									<b>{post.author.name}</b> | <i className="fa fa-clock-o" />{' '}
									Created on <b>{post.publishedAt}</b>
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="main-container generalcontainer">
					<div className="container">
						<div className="row">
							<div className="col-md-8 left-list">
								<div id="generalinerside">
									<div className="container">
										<div id="bodyinner">
											<div
												className="blog-body-inner"
												dangerouslySetInnerHTML={{ __html: marked(post.body) }}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 right-list pull-right">
								<div id="rightside-general-wsc">
									<ul className="listside">
										<li className="consultion">
											<a
												href="tel:1300365125"
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src="https://d33wubrfki0l68.cloudfront.net/img/wsc/981470c895429f41cee44883af4f2004ffce7cc8/talktous-btn.png"
													alt="Talk to us"
												/>
											</a>
										</li>
										<li className="consultion">
											<Link to="/about-us/book-a-free/">
												<img
													src="https://d33wubrfki0l68.cloudfront.net/img/wsc/8dbc6b7c885a7e2cde6f92876981333cc9c32b4e/book-consultation.jpg"
													alt="Book a Free"
												/>
											</Link>
										</li>
										<li className="events">
											<Link to="/upcoming-events">
												<img
													src="https://d33wubrfki0l68.cloudfront.net/img/wsc/3d33217f6ca21e1490555f35db969c6aabed35a9/upcoming-events.jpg"
													alt="Upcoming Events"
												/>
											</Link>
										</li>
										<li className="feedback hidden">
											<a href="https://www.getfeedback.com/r/wP0T3KMy">
												<img
													src="https://d33wubrfki0l68.cloudfront.net/img/wsc/f78baa77edce226d3add21f828d00e74c608c689/feedback-btn.png"
													alt="Feedback"
												/>
											</a>
										</li>
										<li className="freetips">
											<Link to="/about-us/free-download-tips/">
												<img
													src="https://d33wubrfki0l68.cloudfront.net/img/wsc/008a5378df67b29a766e981b2a3b163b8c6c1db2/free-tips.jpg"
													alt="David shaw's"
												/>
											</Link>
										</li>
									</ul>
								</div>
								<div id="rightside-general-wsc2">
									<h2 className="conectside">Connect with WSC Group</h2>
									<ul className="sidesocial">
										<li>
											<a
												href="https://www.linkedin.com/company/wsc-group?trk=tyah&amp;trkInfo=tas%3AWSC+Group%2Cidx%3A1-1-1"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i ariaHidden="true" className="fa fa-linkedin" />
											</a>
										</li>
										<li>
											<a
												href="https://www.facebook.com/wsc.group.aus"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i ariaHidden="true" className="fa fa-facebook" />
											</a>
										</li>
										<li>
											<a
												href="https://plus.google.com/+WscgroupAus"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i ariaHidden="true" className="fa fa-google-plus" />
											</a>
										</li>
										<li>
											<a
												href="https://www.youtube.com/channel/UC1TGGfs2nQyPHSADVPA2ghQ"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i ariaHidden="true" className="fa fa-youtube-play" />
											</a>
										</li>
									</ul>
								</div>
								<div id="rightside-general-wsc3" className="hidden">
									<Link to="/our-services/xero/">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/img/wsc/d6442fa1fee635a98d79a816ad6fd9acd58d1674/why-xero.jpg"
											alt="xero"
										/>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default BlogPostTemplate

export const query = graphql`
	query($id: String!) {
		site {
			siteMetadata {
				title
			}
		}
		sanityBlog(id: { eq: $id }) {
			id
			publishedAt(formatString: "MMMM DD, YYYY")
			body
			title
			author {
				name
			}
		}
	}
`
